<template>
  <v-list-item v-if="checkRightStatus(11)" @click="deleteVendor(vendor)">
    <v-list-item-title>{{ $t("buttons.delete") }}</v-list-item-title>
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";
import vendorService from "../service.js";

export default {
  props: ["vendor"],
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
  },
  methods: {
    deleteVendor() {
      if (!this.checkRightStatus(39)) {
        this.$swal(
          "Delete Customer",
          "You are not allow to delete customer with balance.",
          "warning"
        );
        return false;
      }
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          vendorService
            .delete(this.vendor.id)
            .then((response) => {
              if (response.data) {
                this.$swal("Deleted!", "Vendor has been deleted.", "success");
                this.$emit("submit");
              }
            })
            .catch((error) => {
              throw error;
            });
        }
      });
    },
  },
};
</script>

<style scoped></style>
